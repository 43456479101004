<template>
  <component :is="getComponent" v-bind="$props" />
</template>

<script>
import NonEUDashboardComponent from './NLandLRStatsDialog.vue';
import EUDashboardComponent from './EUStatsDialog.vue';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dashboard: {
      type: String,
      default: 'nl',
    },
  },
  computed: {
    getComponent() {
      return this.dashboard === 'eu' ? EUDashboardComponent : NonEUDashboardComponent;
    },
  },
};
</script>
