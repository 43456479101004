<template>
  <v-dialog v-model="internalShowDialog" max-width="1200px">
    <v-card>
      <v-card-title class="headline">Debug Statistics - EU Dashboard</v-card-title>
      <v-card-text>
        <div v-if="(!statisticsData || Object.keys(statisticsData).length === 0) && !errorHappened && !loaded">
          Please wait...
        </div>
        <div v-else-if="errorHappened">
          Something went wrong when fetching statistics from the backend :c
        </div>

        <div v-else class="d-flex justify-space-between">
          <!-- Original Metadata Section -->
          <div class="metadata-section">
            <v-card-title class="headline">Original Metadata</v-card-title>

            <v-card-subtitle class="text-h5 mt-4 mb-2">References</v-card-subtitle>
            <div v-if="checkForError('references', 'metadata')">
              <p>Error: {{ statisticsData.metadata.references.name }} - {{ statisticsData.metadata.references.errorCode }}</p>
            </div>
            <v-simple-table v-else>
              <thead>
              <tr>
                <th class="text-left">Reference</th>
                <th class="text-right">Count</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(reference, index) in (statisticsData.metadata?.references || [])"
                  :key="index"
                  :class="getComparisonClass('references', reference.name)"
              >
                <td>{{ reference.name }}</td>
                <td class="text-right">{{ reference.count }}</td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="my-4"></v-divider>
          </div>

          <!-- Annotator Metadata Section -->
          <div class="metadata-section ml-5">
            <v-card-title class="headline">Annotator Metadata</v-card-title>

            <v-card-subtitle class="text-h5 mt-4 mb-2">References</v-card-subtitle>
            <div v-if="checkForError('references', 'annotatorMetadata')">
              <p>Error: {{ statisticsData.annotatorMetadata.references.name }} - {{ statisticsData.annotatorMetadata.references.errorCode }}</p>
            </div>
            <v-simple-table v-else>
              <thead>
              <tr>
                <th class="text-left">Reference</th>
                <th class="text-right">Count</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(reference, index) in (statisticsData.annotatorMetadata?.references || [])"
                  :key="index"
                  :class="getComparisonClass('references', reference.name)"
              >
                <td>{{ reference.name }}</td>
                <td class="text-right">{{ reference.count }}</td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="my-4"></v-divider>
          </div>
        </div>
      </v-card-text>
      <v-card-subtitle class="text-h5 mt-4 mb-2" v-if="statisticsData && Object.keys(statisticsData).length > 0">
        Legend
      </v-card-subtitle>
      <v-simple-table v-if="statisticsData && Object.keys(statisticsData).length > 0">
        <thead>
        <tr>
          <th class="text-left">Description</th>
          <th class="text-left">Style</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Present in one list but not the other</td>
          <td><span style="font-weight: bold; color: green">Bold Green</span></td>
        </tr>
        <tr>
          <td>Values differ by 3 or more for references</td>
          <td><span style="font-weight: bold; color: orange">Bold Orange</span></td>
        </tr>
        <tr>
          <td>Values differ by 15 or more</td>
          <td><span style="font-weight: bold; color: red">Bold Red</span></td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalShowDialog: this.value,
      statisticsData: null,
      errorHappened: false,
      loaded: false,
    };
  },
  watch: {
    value(newVal) {
      this.internalShowDialog = newVal;
      if (newVal) {
        this.fetchStatisticsData();
      }
    },
    internalShowDialog(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    closeDialog() {
      this.internalShowDialog = false;
    },
    async fetchStatisticsData() {
      try {
        const response = await this.$store.dispatch('getAllEUStatistics');
        this.statisticsData = response.data || {};
        this.loaded = true;
      } catch (error) {
        this.errorHappened = true;
        this.statisticsData = { error };
      }
    },
    checkForError(category, source) {
      return this.statisticsData[source]?.[category]?.name === 'NoHits';
    },
    getComparisonClass(category, name) {
      const original = this.findInMetadata('metadata', category, name);
      const annotator = this.findInMetadata('annotatorMetadata', category, name);

      if (!original || !annotator) {
        return 'bold-green';
      }

      const originalValue = original.count;
      const annotatorValue = annotator.count;
      const difference = Math.abs(originalValue - annotatorValue);

      if (difference > 15) {
        return 'bold-red';
      }
      if (difference > 3) {
        return 'bold-orange';
      }

      return '';
    },
    findInMetadata(type, category, name) {
      const list = this.statisticsData[type]?.[category];

      if (Array.isArray(list)) {
        return list.find((item) => item.name?.trim() === name.trim());
      }

      return null;
    },
  },
};
</script>

<style scoped>
.metadata-section {
  flex: 1;
  min-width: 300px;
  padding: 10px;
}

.bold-green {
  font-weight: bold;
  color: green;
}

.bold-red {
  font-weight: bold;
  color: red;
}

.bold-orange {
  font-weight: bold;
  color: orange;
}

.v-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
