<!--
  Component which displays the timeline category and mathcing percentage
-->

<template>
  <div class="d-inline-block timeline-percentage mb-n2 ml-1 text-truncate">
    <v-icon class="mt-n1" size="20" :color="color">mdi-file</v-icon>
    <span
      v-if="computedPercentage"
      class="text-h6 grey--text text--darken-1"
    >
      {{ computedPercentage }}%
    </span>
    <span
      v-else-if="institution && sourceUrl"
      class="text-body-2 text--darken-1"
      @click.stop.prevent="openUrlInNewWindow(sourceUrl)"
    >
      {{ computedInstitution }} | {{ computedUrlToSourceName }}
    </span>
    <span
      v-else
      class="text-body-2 text--darken-1"
      @click.stop.prevent="openUrlInNewWindow(sourceUrl)"
    >
      {{ computedUrlToSourceName }}
    </span>
  </div>
</template>

<script>
import { euInstitutionCodeToName } from '@/helpers/groupingHelper';
import urlToSourceName from '@/helpers/urlToSourceNameHelper';

import PropValidators from '@/propValidators';

export default {
  name: 'ListTimelineItemCategory',

  props: {
    // type of the item
    type: {
      type: String,
      required: true,
    },
    // Matching percentage
    percentage: {
      type: [String, Number],
      required: false,
      validator: PropValidators.percentage,
    },
    // url of the item
    sourceUrl: {
      type: String,
      required: false,
    },
    // Institution of the item
    institution: {
      type: String,
      required: false,
    },

  },

  computed: {
    color() {
      if (this.institution === 'EUROCOM') return 'amber accent-2';
      if (this.institution === 'EUROPARL') return 'blue darken-4';
      if (this.institution === 'EUROCOU') return '#FF914D';
      if (this.type === 'SB-EU-Source-4') return '#FF914D'; // Consilium press releases
      if (this.type.startsWith('DH-')) return 'secondary lighten-2';
      if (this.type.startsWith('DS-')) return '#00BFA5';
      if (this.type.startsWith('DS2-')) return '#00BFA5';
      if (this.type.startsWith('DM-')) return 'brown lighten-1';
      if (this.type.startsWith('SB-')) return 'grey lighten-1';
      if (this.type.startsWith('MD-')) return 'purple lighten-1';
      if (this.type.startsWith('STB-')) return '#2EA3F2';
      if (this.type.startsWith('DOJ-')) return '#F09834';
      if (this.type.startsWith('CC-')) return 'cyan darken-3';
      if (this.type.startsWith('PC-')) return 'light-blue darken-4';
      return 'grey lighten-1';
    },

    computedInstitution() {
      return euInstitutionCodeToName(this.institution);
    },

    computedUrlToSourceName() {
      return urlToSourceName(this.sourceUrl).replace('www.', '');
    },

    computedPercentage() {
      if (this.type.startsWith('CC-') || this.type.startsWith('PC-')) {
        return null;
      }
      return this.percentage > 99 ? 100 : this.percentage;
    },

    cssClassCategory() {
      return `timeline-item-category__color-theme--${this.category}`;
    },

  },

  methods: {
    openUrlInNewWindow(url) {
      window.open(url, '_blank');
    },
  },

};
</script>

<style scoped lang="scss">

</style>
