<template>
  <div>
    <div v-if="items.provinces">
      <h4 class="mt-1 ml-3 mb-2">{{ items.provinces[0].localization }}</h4>
      <treeview-root
        class="pl-2 pr-5"
        v-model="nlLrCheckedGroupPathsPr"
        selectable
        selected-color="teal lighten-1"
        :items="items.provinces"
        :openItems="[]"
        @input="handleCheck"
        item-key="groupPath"
        item-text="localization"
        :virtual-root-node="true"
      />
    </div>
    <div v-if="items.cityCouncils">
      <h4 class="mt-1 ml-3 mb-2">{{ items.cityCouncils[0].localization }}</h4>
      <treeview-root
        class="pl-2 pr-5"
        v-model="nlLrCheckedGroupPathsMun"
        selectable
        selected-color="teal lighten-1"
        :items="items.cityCouncils"
        :openItems="[]"
        @input="handleCheck"
        item-key="groupPath"
        item-text="localization"
        :virtual-root-node="true"
      />
    </div>
  </div>
</template>

<script>
import TreeviewRoot from '@/components/Treeview/Treeview.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { convertGroupToFilterStructure } from '@/helpers/groupingHelper';

export default {
  components: {
    TreeviewRoot,
  },

  data: () => ({
    nlLrCheckedGroupPathsMun: [],
    nlLrCheckedGroupPathsPr: [],
    filterType: 'nlLrGrouping',
    showTooltip: false,
  }),

  computed: {
    ...mapGetters({
      allSearchParameters: 'allSearchParameters',
      searchParameterGroupPaths: 'searchParameterGroupPaths',
      userFilters: 'userFilters',
    }),

    items() {
      const grouping = this.userFilters[this.filterType];
      // Update grouping details for searchParameters
      const { name, version } = grouping;
      this.setGroupingDetails({ name, version });

      // Here we manually break open the grouping so we can render it in different sections, with headers.
      const items = [
        { groupCode: 'MUN', varName: 'cityCouncils' },
        { groupCode: 'PR', varName: 'provinces' },
      ].reduce((filters, { groupCode, varName }) => {
        if (grouping.groups[groupCode]) {
          filters[varName] = [convertGroupToFilterStructure(groupCode, grouping.groups[groupCode])];
        }
        return filters;
      }, {});
      return items;
    },
  },

  watch: {
    searchParameterGroupPaths(newGroupPaths) {
      // TODO: do we want to keep this filtering?
      this.nlLrCheckedGroupPathsMun = newGroupPaths
        ? newGroupPaths.filter((groupPath) => groupPath.startsWith('MUN'))
        : [];
      this.nlLrCheckedGroupPathsPr = newGroupPaths
        ? newGroupPaths.filter((groupPath) => groupPath.startsWith('PR'))
        : [];
    },
  },

  methods: {
    ...mapState(['searchParameters']),
    // TODO: Check naming for filters
    ...mapActions([
      'nlLrGetAllTimelineItems',
      'setGroupPaths',
      'setGroupingDetails',
    ]),

    async setQueryAndFilters(groupPaths) {
      // Set the selected filters as querystring of the dashboard
      const filters = {
        groupPaths: groupPaths ? groupPaths.join(',') : [],
      };

      await this.$router.push({
        name: 'nlLrDashboard',
        query: { ...this.$route.query, ...filters },
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log('route', err);
      });

      await this.nlLrGetAllTimelineItems();
    },

    handleCheck() {
      const groupPaths = [
        ...this.nlLrCheckedGroupPathsMun,
        ...this.nlLrCheckedGroupPathsPr,
      ];
      this.setGroupPaths(groupPaths);
      this.setQueryAndFilters(groupPaths);
    },

    groupLocalization(groupPath, locale = 'nl') {
      const grouping = this.userFilters[this.filterType];
      if (grouping && grouping.groups[groupPath]) {
        const localization = grouping.groups[groupPath].localization[locale];
        return localization.plural || localization;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
