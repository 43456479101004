<template>
  <v-dialog v-model="internalShowDialog" max-width="1200px">
    <v-card>
      <v-card-title class="headline">Debug Statistics</v-card-title>
      <v-card-text>
        <div v-if="(!statisticsData || Object.keys(statisticsData).length === 0) && !errorHappened && !loaded">
          Please wait...
        </div>
        <div v-else-if="errorHappened">
          Something went wrong when fetching statistics from the backend :c
        </div>

        <div v-else class="d-flex justify-space-between">
          <!-- Original Metadata Section -->
          <div class="metadata-section">
            <v-card-title class="headline">Original Metadata</v-card-title>

            <v-card-subtitle class="text-h5 mt-4 mb-2">Trending Topics</v-card-subtitle>
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-left">Topic</th>
                <th class="text-right">Count</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(topic, index) in (statisticsData.metadata?.trendingTopics || [])"
                  :key="index"
                  :class="getComparisonClass('trendingTopics', topic.topic)"
              >
                <td>{{ topic.topic }}</td>
                <td class="text-right">{{ topic.count }}</td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="my-4"></v-divider>

            <v-card-subtitle class="text-h5 mt-4 mb-2">Stakeholders</v-card-subtitle>
            <div v-for="(stakeholders, category) in (statisticsData.metadata?.stakeholders || {})" :key="category">
              <v-subheader class="text-h6 mb-2">{{ category }}</v-subheader>
              <v-simple-table>
                <thead>
                <tr>
                  <th class="text-left">Stakeholder</th>
                  <th class="text-right">Count</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(stakeholder, index) in stakeholders"
                    :key="index"
                    :class="getComparisonClass('stakeholders', stakeholder.name || stakeholder.lastName)"
                >
                  <td>{{ stakeholder.name || `${stakeholder.firstName} ${stakeholder.lastName}` }}</td>
                  <td class="text-right">{{ stakeholder.count }}</td>
                </tr>
                </tbody>
              </v-simple-table>
              <v-divider class="my-4"></v-divider>
            </div>
          </div>

          <!-- Annotator Metadata Section -->
          <div class="metadata-section ml-5">
            <v-card-title class="headline">Annotator Metadata</v-card-title>

            <v-card-subtitle class="text-h5 mt-4 mb-2">Trending Topics</v-card-subtitle>
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-left">Topic</th>
                <th class="text-right">Count</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(topic, index) in (statisticsData.annotatorMetadata?.trendingTopics || [])"
                  :key="index"
                  :class="getComparisonClass('trendingTopics', topic.topic)"
              >
                <td>{{ topic.topic }}</td>
                <td class="text-right">{{ topic.count }}</td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-divider class="my-4"></v-divider>

            <v-card-subtitle class="text-h5 mt-4 mb-2">Stakeholders</v-card-subtitle>
            <div v-for="(stakeholders, category) in (statisticsData.annotatorMetadata?.stakeholders || {})" :key="category">
              <v-subheader class="text-h6 mb-2">{{ category }}</v-subheader>
              <v-simple-table>
                <thead>
                <tr>
                  <th class="text-left">Stakeholder</th>
                  <th class="text-right">Count</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(stakeholder, index) in stakeholders"
                    :key="index"
                    :class="getComparisonClass('stakeholders', stakeholder.name || stakeholder.lastName)"
                >
                  <td>{{ stakeholder.name || `${stakeholder.firstName} ${stakeholder.lastName}` }}</td>
                  <td class="text-right">{{ stakeholder.count }}</td>
                </tr>
                </tbody>
              </v-simple-table>
              <v-divider class="my-4"></v-divider>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-subtitle class="text-h5 mt-4 mb-2" v-if="statisticsData && Object.keys(statisticsData).length > 0">
        Legend
      </v-card-subtitle>
      <v-simple-table v-if="statisticsData && Object.keys(statisticsData).length > 0">
        <thead>
        <tr>
          <th class="text-left">Description</th>
          <th class="text-left">Style</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Present in one list but not the other</td>
          <td><span style="font-weight: bold; color: green">Bold Green</span></td>
        </tr>
        <tr>
          <td>Values differ by over 3% for stakeholders or 3 or more for topics</td>
          <td><span style="font-weight: bold; color: orange">Bold Orange</span></td>
        </tr>
        <tr>
          <td>Values differ by over 15% for stakeholders or 15 or more for topics</td>
          <td><span style="font-weight: bold; color: red">Bold Red</span></td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalShowDialog: this.value,
      statisticsData: null,
      errorHappened: false,
      loaded: false,
    };
  },
  watch: {
    value(newVal) {
      this.internalShowDialog = newVal;
      if (newVal) {
        this.fetchStatisticsData();
      }
    },
    internalShowDialog(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    closeDialog() {
      this.internalShowDialog = false;
    },
    async fetchStatisticsData() {
      try {
        const response = await this.$store.dispatch('getAllNLandLRStatistics');
        this.statisticsData = response.data || {};
        this.loaded = true;
      } catch (error) {
        this.errorHappened = true;
        this.statisticsData = { error };
      }
    },
    getComparisonClass(category, name) {
      const original = this.findInMetadata('metadata', category, name);
      const annotator = this.findInMetadata('annotatorMetadata', category, name);

      if (!original || !annotator) {
        return 'bold-green';
      }

      let originalValue;
      let annotatorValue;

      if (category === 'trendingTopics') {
        originalValue = original.count;
        annotatorValue = annotator.count;
      }

      if (category === 'stakeholders') {
        originalValue = original.count;
        annotatorValue = annotator.count;
      }

      const difference = Math.abs(originalValue - annotatorValue);

      if (difference > 15) {
        return 'bold-red';
      } if (difference > 3) {
        return 'bold-orange';
      }

      return '';
    },
    findInMetadata(type, category, name) {
      let list = [];

      if (category === 'trendingTopics') {
        list = this.statisticsData[type]?.trendingTopics || [];
      } else if (category === 'stakeholders') {
        list = Object.values(this.statisticsData[type]?.stakeholders || {}).flat();
      }

      return list.find((item) => item.topic?.trim() === name.trim()
          || item.lastName?.trim() === name.trim()
          || item.name?.trim() === name.trim());
    },
  },
};
</script>

<style scoped>
.metadata-section {
  flex: 1;
  min-width: 300px;
  padding: 10px;
}

.bold-green {
  font-weight: bold;
  color: green;
}

.bold-red {
  font-weight: bold;
  color: red;
}

.bold-orange {
  font-weight: bold;
  color: orange;
}

.v-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
